import React, { useEffect, useState } from 'react';
import '../App.css';
import { Button, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Input, Label, Table } from 'reactstrap';
import { useParams,Link, useNavigate, useLocation } from 'react-router-dom';
import CancelArrow from "../Buttons/CancelArrow";
import HeaderBar from '../NavBars/HeaderBar';
import GeneralButton from '../Buttons/GeneralButton';
import '../App.css';
import CurrencyFormDropDown from '../Currency/CurrencyFormDropDown';

/**
 * This handles both create and edit of an Event
 * @returns Create or Edit page
 */
const EventEdit = () =>
{
    const {id} = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const state:any = location.state;
    const previousURL = state.previousURL;
    const initialFormState = {
        name:'',
        id:'',
        convertTo:null
      };

    const [event,setEvent] = useState<any>(initialFormState);

    const initialErrorState = {
      eventName: '',
      convertTo: ''
    }

    const [errors, setErrors] = useState<any>(initialErrorState);

    useEffect(() => {
      if (id !== 'new') {
        fetch(`/api/events/id/${id}`)
        .then(response => {
            if(response.status === 401) {
                navigate('/login');
            }
            return response.json();
        })
        .then(data => 
            setEvent(data.singleEvent)
          );
      }
    },[id]);

    const handleChange = (e:any) => {
        const { name, value } = e.target
        setEvent({ ...event, [name]: value })    
    }

    let returnedEventId:any;


    // this handles creation and edit depending on whether there is already event id or not
    const handleSubmit = async (e:any) => {
        e.preventDefault();
        let hasError = false;
        setErrors(initialErrorState);

        if(event.convertTo === null) {
          setErrors((prevState:any) => ({...prevState, convertTo : 'Please select default currency'}))
          hasError = true;
        }

        if(event.name === '') {
          setErrors((prevState:any) => ({...prevState, eventName : 'Please enter event name'}))
          hasError = true;
        }

        if(hasError) {
          console.log("has error");
          return;
        }

        await fetch('/api/events/update',
        {
          method: (event.id) ? 'PUT' : 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(event)
        })
        .then(response => {
            if(response.status === 401) {
                navigate('/login');
            }
            return response.json();
        })
        .then(data => {
            returnedEventId = data;
        });
        setEvent(initialFormState);
        
        if(returnedEventId == null) {
          returnedEventId = event.id;
        }
        navigate('/events/id/' + returnedEventId);
    }

    const title = <h2>{event.id ? 'Edit Event' : 'Create Event'}</h2>;
    const [currencyDropdownOpen, setCurrencyDropdownOpen] = useState(false);

    const toggleCurrencyDropdown = () => {
      setCurrencyDropdownOpen(!currencyDropdownOpen);
    };

    return (
          <div>
              <Container>
              <CancelArrow URL={previousURL}/>
                {title}
                {/* <ToggleSwitch/> */}
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Label for="name">Event Name</Label>
                    <Input type="text" name="name" id="name" value={event.name || ''}
                          onChange={handleChange} autoComplete="name"/>
                    {errors.eventName && <p className='submit-error-message-text'>{errors.eventName}</p>}
                  </FormGroup>
                  <CurrencyFormDropDown 
                    currencyType={event.convertTo}
                    handleChange={handleChange}
                    dropdownItemName='convertTo'
                    errorMessage={errors.convertTo}/>
                  <FormGroup>
                    <GeneralButton>Save</GeneralButton>{' '}
                  </FormGroup>
                </Form>
              </Container>
          </div>
    )
}

export default EventEdit;