import HeaderBar from "../NavBars/HeaderBar";
import BottomSection from "./BottomSection";

const PrivacyPolicy = () => {
    return (
        <div>
            <HeaderBar isWelcome={true}/>
            <div>
            <section className="middle-section">
                <h2>Privacy Policy</h2>
                <div className="feature">
                    <p className="feature-description">
                        Effective date : 2023 / 12 / 25
                    </p>
                </div>
                <h3>1. Introduction</h3>
                <div className="feature">
                    <p className="feature-description">
                    Welcome to Split App. We respect your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our web app.
                    </p>
                </div>
                <h3>2. Information We Collect</h3>
                <div className="feature">
                    <p className="feature-description">
                        <b>a. Personal Information</b><br/>
                        <b>Username (Email)</b> When you create an account, we collect your email address as your username for authentication purposes.<br/>
                        <b>Nickname</b>You have the option to choose a nickname, which can be anything and does not have to be personally identifiable.
                    </p>
                    <p className="feature-description">
                        <b>b. Non-Personal Information</b><br/>
                        We collect non-personal information, such as browser type, device information, and usage data, for the purpose of improving our services and user experience.
                    </p>
                </div>
                <h3>3. How We Use Your Information</h3>
                <div className="feature">
                    <p className="feature-description">
                        <p>We use the information we collect for the following purposes:</p>
                        <p><b>Account Creation:</b> To create and manage user accounts.</p>
                        <p><b>Authentication:</b> To authenticate users and provide secure access to the app.</p>
                        <p><b>Session Management:</b> To retain session information and enhance user experience during app usage.</p>
                        <p><b>Communication:</b> To send occasional emails regarding updates, announcements, and account-related information.</p>
                    </p>
                </div>
                <h3>4. Cookies</h3>
                <div className="feature">
                    <p className="feature-description">
                        <p>We use cookies for authentication and session management only. Cookies are not used to track user activity. The following cookies are utilized:</p>
                        <p><b>sideKick:</b> Authentication cookie.</p>
                        <p><b>sideKick-refresh:</b> Authentication cookie.</p>
                        <p><b>sideKick-session:</b> Session management cookie.</p>
                    </p>
                </div>
                <h3>6. Changes to This Privacy Policy</h3>
                <div className="feature">
                    <p className="feature-description">
                        <p>We reserve the right to update this Privacy Policy at any time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
                    </p>
                </div>
                <h3>7. Contact Us</h3>
                <div className="feature">
                    <p className="feature-description">
                        <p>If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:thenecessaryapp@gmail.com">thenecessaryapp@gmail.com</a>.</p>
                    </p>
                </div>
            </section>
        </div>
            <BottomSection/>
        </div>
    )
}

export default PrivacyPolicy;