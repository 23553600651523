import React, { ReactNode } from 'react';
import './MainContentContainerCSS.css';

interface MainContentContainerProps {
    children: ReactNode;
    className?: string;
  }

const MainContentContainer:React.FC<MainContentContainerProps> = ({ children, className }) => {
  return <div className={`main-content-container ${className ? className : ''}`} >{children}</div>;
};

export default MainContentContainer;
