import { useNavigate, useParams } from "react-router-dom";
import useAPIService from "../APIHandler/APIService";
import { useEffect } from "react";

const EventJoin = () => 
{
    const apiService = useAPIService();
    const {id} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        fetch(`/api/events/join/${id}`)
        .then(response => {
            if(response.ok) {
                return response.json();
            } else {
                apiService.redirectToLoginPage();
            }
        })
        .then(data => {
            navigate(data.redirectURL);
        });

    },[]);
    return null;
}

export default EventJoin;