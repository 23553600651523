import { APP_NAME } from '../Constants';
import './HeaderBarCSS.css';
import MobileNavigationMenu from "./MobileNavigationMenu";
import WelcomeNavBar from './WelcomeNavBar';

const HeaderBar = ({disableNav = false, isWelcome = false}) =>
{
    if(disableNav) {
        return ( 
            <header id="header" className='header'>
                <p className="appLogo"><a href="/">{APP_NAME}</a></p>
            </header>
        );
    }

    return ( 
        <header id="header" className='header'>
            { isWelcome ? <WelcomeNavBar/> : <MobileNavigationMenu/> } 
            <p className="appLogo"><a href="/">{APP_NAME}</a></p>
        </header>
    );
}

export default HeaderBar;