import React from 'react';
import './LandingPageCSS.css';
import { Twitter } from 'react-feather';
import { APP_NAME } from '../Constants';

const BottomSection = () => {
  const currentFullYear = new Date().getFullYear();
  return (
    <section className="bottom-section">
      <nav>
        <ul className="bottom-links">
          <li><a href="/">Home</a></li>
          <li><a href="/blogs">Developer Blog</a></li>         
          <li><a href="/faq">FAQ</a></li>
          <li><a href="mailto:thenecessaryapp@gmail.com">Contact Us</a></li>
          <li><a href="/privacy">Privacy policy</a></li>
        </ul>
      </nav>
      <div className='social-links'>
        <a href="https://twitter.com/split_app_"><Twitter color='white'/></a>
      </div>
      <div className="splitapp-copyright">
        <p>&copy; {currentFullYear} {APP_NAME}</p>
      </div>
    </section>
  );
};

export default BottomSection;
