import React, { useEffect, useMemo, useState } from 'react';
import {Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import TransactionList from '../Payment/TransactionList';
import './EventListByUserId';
import '../Banner.css';
import '../EventMembers/EventMembers.css';
import useAPIService from "../APIHandler/APIService";
import HeaderBar from '../NavBars/HeaderBar';
import MainContentContainer from '../Containers/MainContentContainer';
import { DollarSign, Edit2, UserPlus, Users } from 'react-feather';
import LoadingAnimation from '../Animations/Loading/LoadingAnimation';
import Message from '../Helper/Messaging/Message';
import './EventPageSingleCSS.css';

const EventPageSingle = () => {
    let {state} = useLocation();
    const location:any = useLocation();
    const ownURI = location.pathname;
    const {id} = useParams();
    const [singleEvent, setSingleEvent] = useState<any>({});
    const [loading, setLoading] = useState(true);
    const stateValues:any = state;
    const navigate = useNavigate();
    const messaging = location.state?.messaging;
    console.log("messaging : " + messaging);
    const doShowBanner = stateValues != null;
    const apiService = useAPIService();

    let resultMessage:string;
    if(doShowBanner) {
        resultMessage = stateValues.resultMessage;
    } else {
        resultMessage = "";
    }
    let bannerMessage = useMemo(() => resultMessage,[]);

    useEffect(() => {
      const response = apiService.getSingleEventByID(`${id}`);
      response.then(data =>
      {
        setSingleEvent(data.singleEvent);
        setLoading(false);
      }
      );
    }, [`${id}`]);

    if (loading) {
      return <div>
                <HeaderBar/>
                <LoadingAnimation/>
             </div>;
    }

    const editAction = () => {
        navigate(`/events/${id}`,{
            state : {
                previousURL : ownURI
            }
        });
    }

    const handleClick = (event:any) => {
        if (event.target.id === 'showMembers') {
            navigate(`/events/members/id/${id}`)
        } else if (event.target.id === 'createEventMember') {
            navigate('/events/members/add', {
                state : {
                    eventId : `${id}`,
                    url : ownURI
                  }
            });
        } else if (event.target.id === 'eventEdit') {
            navigate(`/events/${id}`);
        }
    }

    const addPaymentAction = () => {
        navigate(`/events/id/${id}/payment/new`,{
            state : {
                previousPageURL : location.pathname
            }
        });
    }

    return (
        <div>
            <HeaderBar />
            <MainContentContainer>
                <div id="snackbar">{bannerMessage}</div>
                <div className="top-row">
                    <div className="event-page">
                        Event Page
                    </div>
                    <div className="icons-container">
                        <div className="icon-item">
                            <Edit2 id="eventEdit" size={20} onClick={editAction} className='edit-event-button'></Edit2>
                            <div className='icon-label'>edit</div>
                        </div>
                        <div className="icon-item">
                            <div className='members-icon'><Users id="showMembers" size={20} onClick={handleClick} className='show-members-button'></Users></div>
                            <div className='icon-label'>members</div>
                        </div>
                        <div className="icon-item">
                            <div className='add-member-icon'><UserPlus id="createEventMember" size={20} onClick={handleClick} className='create-button'></UserPlus></div>
                            <div className='icon-label'>add member</div>
                        </div>
                        <div className="icon-item">
                            <div className='add-payment-icon'><DollarSign size={20} onClick={addPaymentAction}/></div>
                            <div className='icon-label'>add payment</div>
                        </div>
                    </div>
                </div>
                <div className="bottom-row">
                    <div><h2 key={singleEvent.id}>{singleEvent.name}</h2></div>
                    <TransactionList />
                    {messaging && <Message {...messaging} />}
                </div>
            </MainContentContainer>
        </div>
    );
    
    
}

export default EventPageSingle;