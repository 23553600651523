import './LandingPageCSS.css';

const FaqMiddleSection = () => {
    return (
        <div>
            <section className="middle-section">
                <h2>FAQ</h2>
                <h3>How are payments split?</h3>
                <div className="feature">
                    <p className="feature-description">
                        The app is designed for optimal efficiency in splitting payments among users. We've implemented an algorithm that minimizes the number of payment rounds required, ensuring a streamlined and straightforward settlement process. For instance, if Person A owes $100 to Person B, Person A owes $50 to Person C, and Person C owes $50 to Person B, our system consolidates these transactions. Instead of three separate transactions, the optimized solution involves Person A making a single payment of $150 directly to Person B, effectively canceling out other transactions within the group. 
                    </p>
                </div>
                <h3>How are the foreign currencies calculated?</h3>
                <div className="feature">
                    <p className="feature-description">
                        The system is designed to retrieve real time currency rate, and uses the corresponding rate for the date that payment occured.
                    </p>
                </div>
                <h3>How do I request for additional available currency in the app?</h3>
                <div className="feature">
                    <p className="feature-description">
                       Feel free to email us from the "Contact Us" section at the bottom of this page.
                    </p>
                </div>
            </section>
        </div>
      );
}

export default FaqMiddleSection;