import { APP_NAME } from "../../Constants";
import HeaderBar from "../../NavBars/HeaderBar";
import BottomSection from "../BottomSection";
import './BlogCSS.css';

const FirstBlog = () => {
    return (
        <div>
            <HeaderBar isWelcome={true}/>
            <div className="blog-body-container">
                <section className="blog-middle-section">
                    <h1 className="blog-title">First Blog Post</h1>
                    <div className="feature">
                        <p className="with-border">
                            December 27th, 2023
                        </p>
                    </div>
                    <div className="feature">
                        <p className="feature-description">
                            Embarking on a journey with friends and family, or sharing a delightful meal together, has always been a source of joy for me.
                            The camaraderie and laughter during home parties, where diverse foods and drinks are enjoyed within the group, create lasting memories. 
                            However, amidst the joy, one aspect has consistently posed a challenge – settling the intricate web of payments that inevitably arise from such gatherings. 
                            Whether it's pooling funds for a beer run or ordering a plethora of pizzas to accompany a spirited football game, the post-event discussion of "Who paid what, and who owes who, and how much?" can be far from enjoyable. 
                            At times, I even found myself giving up on collecting my own dues.
                        </p>
                        <p className="feature-description">
                            In my quest for a solution, I explored various web applications designed to streamline these payment settlements. 
                            Regrettably, none quite met my specific requirements. As a software engineer, I took it upon myself to address this common predicament and thus developed this <b>{APP_NAME}</b>.
                        </p>
                        <p>
                            As of typing this article, the Split App boasts a robust feature set that revolves around the concept of "Events." 
                            Users can create a dedicated "Event" for their gathering, whether it's a vacation with friends to Hawaii or any other social occasion. 
                            By sharing a unique link to the created "Event," friends can seamlessly join in. 
                            Clicking the link prompts them to create an account, and just like that, they become integral members of your event. 
                            Throughout your time together, any payments made among friends can be effortlessly recorded within the app, and the algorithm takes care of the rest – calculating who owes what in real-time.
                        </p>
                        <p>
                            Having personally integrated the Split App into my social circles, I can attest to its efficacy in saving both time and stress. 
                            What sets this app apart is its retrospective functionality – users can revisit past events and peruse the detailed payment history. 
                            To provide this experience, account creation is a prerequisite, adding a layer of personalized convenience to the experience.
                        </p>
                        <p>
                            Looking ahead, I am committed to enhancing the Split App by incorporating additional features that further alleviate the stress associated with managing group expenses. 
                            The goal is to provide users with a comprehensive solution that not only addresses the current challenges but also anticipates and mitigates future complexities. 
                            As we collectively navigate the realms of social events, the Split App stands as a testament to the power of innovative problem-solving and the profound impact it can have on our day-to-day lives.
                        </p>
                    </div>
                </section>
        </div>
            <BottomSection/>
        </div>
    )
}

export default FirstBlog;