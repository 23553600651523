import React, { useEffect, useState } from 'react';
import '../App.css';
import { Table } from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom';
import useAPIService from "../APIHandler/APIService";
import HeaderBar from '../NavBars/HeaderBar';
import LoadingAnimation from '../Animations/Loading/LoadingAnimation';
import { Edit, Trash } from 'react-feather';
import './EventMemberListCSS.css';

const EventMemberList = () => {
    const {id} = useParams();
    const [eventMembers, setEventMember] = useState<any[]>([]);
    const apiService = useAPIService();
    const [loading,setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const response = apiService.getEventMembers(`${id}`);
        response.then(data => {
                setEventMember(data.eventMembers);
            }
        );
        setLoading(false);
    }, [`${id}`]);

    if(loading) {
        return <div>
               <HeaderBar/>
               <LoadingAnimation/>
                </div>
    }

    const handleActionClick = (e:any, memberId:string) => {
        // Seems the name element passed on from react-feather icons need to be retrieved like this specifically
        const name = e.target.getAttribute('name');

        if(name === "edit_member") {

        } else if (name === "delete_member") {
            const confirmed = window.confirm('Delete member?');
            if(!confirmed) {
                return;
            }
            deleteAction(memberId);
        }
    }

    const deleteAction = async(memberId:string) => {
        await fetch(`/api/events/delete/eventMember/id/${id}/${memberId}`, {
            method : "DELETE"
        })
        .then(response => response.json())
        .then(data => {
            if(data.redirectURL) {
                navigate(data.redirectURL);
            }
        });
    }

    return (
        <div>
            <HeaderBar/>
                <h2>Member List</h2>
                <Table striped bordered hover>
                <thead>
                    <tr>
                        <th className='member-list-cells'>Name</th>
                    </tr>
                </thead>
                <tbody>
                    {eventMembers.map(eventMember =>
                        <tr key={eventMember.id}>
                            <td className='member-list-cells'>{eventMember.name}</td>
                            <td className='action-list-cells member-list-actions'>
                                <div><Edit name='edit_member' onClick={(e) => handleActionClick(e,eventMember.id)} size={15}/></div>
                                {eventMember.isDeletable && <div><Trash name='delete_member' onClick={(e) => handleActionClick(e,eventMember.id)} size={15}/></div> }
                            </td>
                        </tr>
                        )}
                </tbody>
                </Table>
        </div>
    );
}

export default EventMemberList;