
const PaymentAmount = ({currencyType, amount, noRendering=false}:any) => {
    console.log('currencytype is ' + currencyType);
    console.log('amount is ' + amount);
    switch(currencyType) {
        case 'JPY':
            return Math.round(amount);
        default :
            // factor will be power of 10, to move the decimal points
            const factor = Math.pow(10, 2);
            // Round the number by first multiplying with the factor, which moves the decimal points to specified position
            // Then, divide it with the factor which puts back the decimal point
            return Math.round(amount * factor) / factor;
    }
}

export default PaymentAmount;