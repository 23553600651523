import React from 'react';
import './ThreadBoxesCss.css'; // Import the CSS file
import GeneralButton from '../Buttons/GeneralButton';
import { useLocation, useNavigate } from 'react-router-dom';
import '../Payment/PaymentAdjustmentCSS.css';
import PaymentAmount from './PaymentAmount';

export interface transactionState {
  subjectMemberId?:string,
  subjectMemberName?:string,
  id:string,
  targetMemberId:string,
  targetMemberName:string,
  amount: number,
  currencyType: string,
  currencySymbol: string,
  eventId?: string
}

export interface transactionSubjectMember {
  name:string,
  id:string
}

export interface transactionTreeModel {
  subjectMember: transactionSubjectMember,
  transactionStates: transactionState[]
}

const TransactionMap:React.FC<transactionTreeModel> = ({subjectMember, transactionStates}) => {
  const countOfTransactions = transactionStates.length;
  const location = useLocation();

  const normalBranch = (name:string, amount:number, currencySign:string = '¥', currencyType:string) => {
    return <div className='branch-container'>
            <div className='branch'>
              <svg width="50" height="27" xmlns="http://www.w3.org/2000/svg">
                <line x1="10" y1="0" x2="10" y2="30" stroke="#ccc" strokeWidth="1" />
                <line x1="10" y1="10" x2="50" y2="10" stroke="#ccc" strokeWidth="1" />
              </svg>
              <span className='branch-text'>{name}</span>
              <div className="thread-amount-styling thread-remaining-amount-text"><span className={amount > 0 ? 'overpaying-value' : amount < 0 ? 'remaining-debt-value' : ''}>{currencySign}{PaymentAmount({currencyType, amount})}</span></div>
            </div>
          </div> 
  }

  const finalBranch = (name:string, amount:number, currencySign:string = '¥', currencyType:string) => {
    return  <div className='branch-container'>
              <div className='branch'>
                <svg width="50" height="20" xmlns="http://www.w3.org/2000/svg">
                  <line x1="10" y1="0" x2="10" y2="10.5" stroke="#ccc" strokeWidth="1" />
                  <line x1="10" y1="10" x2="50" y2="10" stroke="#ccc" strokeWidth="1" />
                </svg>
                <span className='branch-text'>{name}</span>
                <div className="thread-amount-styling thread-remaining-amount-text"><span className={amount > 0 ? 'overpaying-value' : amount < 0 ? 'remaining-debt-value' : ''}>{currencySign}{PaymentAmount({currencyType, amount})}</span></div>
              </div>
            </div>
  }

  return (
    <div className={`thread-message`}>
      <div className='thread-top-row-container'>
        <div className='thread-subject'>{subjectMember.name}</div>
        <div className='thread-remaining-amount-col'>remaining</div>
        <div className='thread-button-col'><GeneralButton size='small' to='/paymentAdjustment' state={{transactionStates, location}}>Payback</GeneralButton></div>
      </div>
      <div className='column-container'>
        {transactionStates.map((transactionState, index) => {
          const isLastTransaction = index + 1 === countOfTransactions;
          const currencySign = transactionState.currencySymbol;
          const currencyType = transactionState.currencyType;
          return isLastTransaction ? finalBranch(transactionState.targetMemberName, transactionState.amount, currencySign, currencyType)
                                   : normalBranch(transactionState.targetMemberName, transactionState.amount, currencySign, currencyType)
         })}
      </div>
    </div>
  );
  
};
export default TransactionMap;