import {useLocation, useNavigate} from "react-router-dom";
import {Container, Table} from "reactstrap";
import React, {useState} from "react";
import { FcCheckmark } from 'react-icons/fc';
import { PaymentState } from '../Payment/PaymentCreate';

const EventMembersSelector = () => {
    const navigate = useNavigate();
    const location:any = useLocation();
    const referer = location.state.from;
    const eventMemberList:any[] = location.state.eventMembers;
    const eventId:string = location.state.eventId;
    const paidMemberId:string = location.state.selectedPaidMemberId;
    const previousPageURL:string = location.state.previousPageURL;
    const subject:string = location.state.subject;
    const amount = location.state.amount;
    const isPayback = location.state.isPayback;
    const id = location.state.id;
    const currencyType = location.state.currencyType;
    const selectedTargetMemberList:string[] = location.state.selectedTargetMembers;
    const [selectedMemberId,setSelectedMemberId] = useState<string>(paidMemberId);

    if(eventId === null || eventId === '') {
        return <h3>Page not available</h3>;
    }

    if(eventMemberList.length === 0) {
        return <h3>No members added yet</h3>;
    }

    const handleClick = (memberId:string,memberName:string) => {
        const paymentState : PaymentState = {
            subject : subject,
            eventId: eventId,
            previousPageURL: previousPageURL,
            paidMemberId: memberId,
            amount: amount,
            targetMemberIdsList: selectedTargetMemberList,
            id: id,
            paidMemberName : memberName,
            currencyType : currencyType,
            isPayback : isPayback
        }
        setSelectedMemberId(memberId);
        // Delay before navigation to show the user which member is chosen
        setTimeout( () => {
            navigate(referer, {
                state: {
                    paymentState
                }
            })
        },160);
    }

    return (
        <div className="overlay">
            <div className="popup">
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th style={{ border: "none"}}>Select who paid</th>
                </tr>
                </thead>
                <tbody>
                {eventMemberList.map(eventMember =>
                    <tr key={eventMember.id}>
                        <td style={{ border: "none"}} onClick={() => handleClick(eventMember.userId,eventMember.name)} >{eventMember.name}</td>
                         <td style={{ border: "none"}}>  {selectedMemberId === eventMember.userId && <FcCheckmark size={24} color="green" />}
                        </td>
                    </tr>
                )}
                </tbody>
            </Table>
            </div>
        </div>
    );
}

export default EventMembersSelector;