import React, { useEffect, useState } from 'react';
import '../App.css';
import { Link, useParams } from 'react-router-dom';
import './TransactionList.css';
import TransactionMap, { transactionSubjectMember, transactionTreeModel } from './TransactionMap';

/**
 * @returns List of Summarised transactions.
 * Should be used as part of a page, and not as a page on it's own.
 */
const TransactionList = () => 
{
    const {id} = useParams();
    const [transactions, setTransactions] = useState<any[]>([]);
    const [hasPayments,setHasPayments] = useState(false);
    const [transactionMappings, setTransactionMappings] = useState<transactionTreeModel[]>([]);

    useEffect(() => {
      fetch(`/api/event/id/${id}/payment`)
        .then(response => response.json())
        .then(data => {
            if(Object.keys(data).length > 0) {
                setTransactions(data);
                setHasPayments(true);
                for(const key of Object.keys(data)) {
                    const debtTransaction = data[key];
                    // devtTransacgit tion would be an array.
                    // At this point, data retrieved has at least one element in the array
                    // Retrieving the subject member info from the first index
                    const subjectMemberId = debtTransaction[0].subjectMemberId;
                    const subjectMembername = debtTransaction[0].subjectMemberName;
                    const transactionSubjetMember : transactionSubjectMember = {
                        name: subjectMembername,
                        id: subjectMemberId
                    }
                    setTransactionMappings(prevMapping => 
                        [...prevMapping, {subjectMember :transactionSubjetMember,transactionStates:debtTransaction}]
                    );
                }
            }
        })
    }, [`${id}`]);

    // if(!hasPayments) {
    //     return <div>No Payments added yet.</div>
    // }

    const transactionList = transactionMappings.map(transactionMapping => {
        return <div><TransactionMap {...transactionMapping}/></div>
    });

    return ( 
        <div>
            <div className='transaction-list-container'>
                <div className='detail-link'>
                        <Link to={{
                            pathname : `/payment/showAll/${id}`
                        }}>Payment List</Link>
                </div>
                <div>
                    {transactionList}
                </div>
            </div>
        </div>
    );
}

export default TransactionList;