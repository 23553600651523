import React, {useState} from "react";
import './loginPage.css';
import LoginSignUpForm from "./LoginSignUpForm";
import HeaderBar from "../NavBars/HeaderBar";
import {useLocation} from "react-router-dom";
import MobileNavigationMenu from "../NavBars/MobileNavigationMenu";

const LoginPage = () =>
{
    const {state} = useLocation();
    const {pathname} = useLocation();

    let stateValues;
    let doCreateAccount;
    if(pathname === "/new") {
        doCreateAccount = true;
    } else if(state !== undefined && state !== null) {
        stateValues = state;
        // @ts-ignore
        doCreateAccount = stateValues.doCreateAccount;
    } else {
        doCreateAccount = false;
    }

    const SIGNUP_TEXT = "Create a new account";
    const LOGIN_TEXT = "Login";
    const SIGNUP_TITLE = "Create Account";
    const LOGIN_TITLE = "Login";
    const TITLE = doCreateAccount ? SIGNUP_TITLE : LOGIN_TITLE;
    const TEXT = doCreateAccount ? LOGIN_TEXT : SIGNUP_TEXT;

    const [isCreateNewAccount,setIsCreateNewAccount] = useState(doCreateAccount);
    const [pageTitle,setPageTitle] = useState(TITLE);
    const [linkText,setLinkText] = useState(TEXT);

    const handleClick = (clickValue:String) =>
    {
        if(clickValue === SIGNUP_TEXT) {
            setIsCreateNewAccount(true);
            setPageTitle(SIGNUP_TITLE);
            setLinkText(LOGIN_TEXT);
        } else {
            setIsCreateNewAccount(false);
            setPageTitle(LOGIN_TITLE);
            setLinkText(SIGNUP_TEXT);
        }
    }

    const AuthenticationTexts = () =>
    {
        if(!isCreateNewAccount)
            return <div><span>If you do not have an account, </span><span className="spanDeco" onClick={() => {handleClick(linkText)}}>{linkText}</span></div>

        else if(isCreateNewAccount)
            return <div><span>If you already have an account, </span><span className="spanDeco" onClick={ () => { handleClick(linkText) } }>{linkText}</span></div>

        return null;
    }

    return (
        <div>
            <HeaderBar disableNav={true} />
            <div className="form-container">
                <div className="form-child">
                    <div className="loginPageTitle">{pageTitle}</div>
                    <div className="form-inline">
                        <LoginSignUpForm createAccount = {isCreateNewAccount}/>
                        <AuthenticationTexts/>
                        <p className="privacy-text">Read our <a href="/privacy" target="_blank">privacy policy</a></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;