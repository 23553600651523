import { useEffect, useState } from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import './eventHome.css';
import { Users } from 'react-feather';
import LoadingAnimation from '../Animations/Loading/LoadingAnimation';

/**
 * @returns List of events that the given user Id is tied to
 */
const EventListByUserId = () =>
{
    const [events, setEvents] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
      setLoading(true);
  
      fetch('/api/events/getEventList')
        .then(response => response.json())
        .then(data => {
          setEvents(data.eventList);
          setLoading(false);
        })
    }, []);
  
    if (loading) {
      return <div>
                <LoadingAnimation/>
             </div>;
    }
  
    return (
         <div>
            {events.map(event => (
            <div className="data-box" key={event.id}>
                <div className="event-name">
                    <Link to={`id/${event.id}`}>{event.name}</Link>
                </div>
                <div className="start-date">{event.startDate}</div>
                <div className="end-date">{event.endDate}</div>
                <div className="num-of-members">
                    <Link to={`members/id/${event.id}`}><Users size={17}/>{event.numberOfMembers}</Link>
                </div>
            </div>
            ))}
        </div>
      );
}

export default EventListByUserId;