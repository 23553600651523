import { useEffect, useState } from "react";
import useAPIService from "../APIHandler/APIService";

const EventCount = () => {

    const apiService = useAPIService();
    const [eventCount, setEventCount] = useState(0);

    useEffect(() => {
        const response = apiService.getByURL('api/events/getEventCount');
        response.then(data => {
            setEventCount(data.eventCount);
        } )
    },[]);

    return (
        <div>
            <h3>You are currently in <a href='/events'>{eventCount} events</a></h3>
        </div>
    );
}

export default EventCount;