import React, {useState} from "react";
import {Container, Form, FormGroup, Input, Label} from "reactstrap";
import './loginPage.css';
import {doLoginSignUpRequest} from "../Authentication/LoginSignUpRequestHandler";
import { HttpStatusCode } from "axios";
import { useNavigate } from "react-router-dom";

const LoginSignUpForm = (props:any) =>
{
    const doCreateAccount = props.createAccount;
    const EMAIL_PLACEHOLDER = doCreateAccount ? "Please enter NEW email address" : "Please enter email address";
    const PASSWD_PLACEHOLDER = doCreateAccount ? "Please enter NEW password" : "Please enter password";
    const NICKNAME_PLACEHOLDER = "Please enter your nickname";
    const [username,setUsername] = useState("");
    const [password,setPassword] = useState("");
    const [name,setName] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

    const LoginButton = () =>
    {
        let buttonText;
        if(doCreateAccount) {
            buttonText = "Create account";
        } else {
            buttonText = "Login";
        }
        return <button type="submit" className="login-button"> {buttonText} </button>
    }

    const handleChange = (event:any) =>
    {
        event.preventDefault();
        setError("");
        switch(event.target.name) {
            case 'name':
                setName(event.target.value);
                break;
            case 'email':
                setUsername(event.target.value);
                break;
            case 'password':
                setPassword(event.target.value);
        }
    }

    const handleSubmit = async (e:any) =>
    {
        e.preventDefault();
        const endpoint = doCreateAccount ? '/registerUser' : '/authenticate';
        let context = {};
    
        if (doCreateAccount) {
            context = {
                'name': name,
                'username': username,
                'password': password
            }
        } else {
            context = {
                'username': username,
                'password': password
            }
        }

        await fetch(endpoint,{
        method:'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body:JSON.stringify(context)
        })
        .then(response => {
            if(response.status === HttpStatusCode.Unauthorized) {
                throw new Error("Incorrect username / password");
            } else if(response.status !== HttpStatusCode.Ok && response.status !== HttpStatusCode.Created) {
                throw new Error("Something went wrong. Contact support");
            } else {
                return response.json();
            }
        })
        .then(data => {
            navigate(data.redirectURL);
        })
        .catch(error => {
            setError(error.message);
        })

    }

    return (
        <div>
            {doCreateAccount ? (<Form onSubmit={handleSubmit} className="form-inline">
                <FormGroup>
                    <Input placeholder={NICKNAME_PLACEHOLDER} onChange={handleChange} value={name} name="name" type="text"/>
                </FormGroup>
                <FormGroup>
                    <Input placeholder={EMAIL_PLACEHOLDER} onChange={handleChange} value={username} name="email" type="email"/>
                </FormGroup>
                <FormGroup>
                    <Input placeholder={PASSWD_PLACEHOLDER} onChange={handleChange} name="password" value={password} type="password"/>
                </FormGroup>
                <FormGroup>
                    <LoginButton/>
                </FormGroup>
            </Form>) : (
                <Form onSubmit={handleSubmit} className="form-inline">
                    {error && <span className="submit-error-message-text">{error}</span>}
                    <FormGroup>
                        <Input placeholder={EMAIL_PLACEHOLDER} onChange={handleChange} value={username} name="email" type="email"/>
                    </FormGroup>
                    <FormGroup>
                        <Input placeholder={PASSWD_PLACEHOLDER} onChange={handleChange} name="password" value={password} type="password"/>
                    </FormGroup>
                    <FormGroup>
                        <LoginButton/>
                    </FormGroup>
                </Form>
            )}
        </div>
    );
}
export default LoginSignUpForm;