import React from 'react';
import './LandingPageCSS.css';
import { Link } from 'react-router-dom';
import { APP_NAME } from '../Constants';

const MiddleSection = () => {
  return (
    <section className="middle-section">
      <h2>What is {APP_NAME}?</h2>
      <div className="feature">
      <p className="feature-description">Ever experienced the headache of splitting payments within your group? Planning events, vacations, or even a casual night out can quickly turn into a financial puzzle. Who paid for dinner? Who covered the hotel? And most importantly, who owes who?
      <br/>Introducing <b>{APP_NAME}</b> – your ultimate solution for hassle-free group expense management! splitApp summarises payments with in your group, and calculates who owes who as soon as you start adding payments. </p>
      </div>
      <h2>Key features</h2>
      <div className="feature">
        <h3>Create and Manage Events</h3>
        <p className="feature-description">Create events to efficiently manage your gatherings and expenses.</p>
      </div>
      <div className="feature">
        <h3>Expense Tracking</h3>
        <p className="feature-description">Each member can easily add their expenses, keeping everything organized.</p>
      </div>
      <img
        src="payments.png"
        alt="recording payments"
        className="gif-image-with-border"
      />
      <div className="feature">
        <h3>Real-Time Payment Splitting</h3>
        <p className="feature-description">See the result of splitting payments in real time as new expenses are added.</p>
      </div>
      <img
        src="optimized_payment.png"
        alt="split payment"
        className="gif-image-with-border"
      />
      <div className="feature">
        <h3>Multi-Currency Support</h3>
        <p className="feature-description">Experience the convenience of handling expenses in multiple currencies.</p>
      </div>
      <div className='how-to-get-started'>
        <h2>How to get started</h2>
        <div className="how-to-proceed">
          <span>1. Click on "Get Started" button at the top or bottom of this page</span>
        </div>
        <div className="how-to-proceed">
          <span>2. Create a new account</span>
        </div>
        <img
          src="create_account.png"
          alt="create new account"
          className="gif-image-with-border"
        />
        <div className="how-to-proceed">
          <span>3. Click on the 0 events link</span>
        </div>
        <img
          src="home_page.png"
          alt="home page"
          className="gif-image-with-border"
        />
        <div className="how-to-proceed">
          <span>4. Click on Create Event button</span>
        </div>
        <img
          src="create_event_button.png"
          alt="create event button"
          className="gif-image-with-border"
        />
        <div className="how-to-proceed">
          <span>5. Fill in the fields. Choose the currency that you would like to settle the payment with in your group. Click Save.</span>
        </div>
        <img
          src="create_event.png"
          alt="create event"
          className="gif-image-with-border"
        />
        <div className="how-to-proceed">
          <span>6. Congratulations! You've created your first event. With in this event, add you friends to it, add payment and the app will do the calculation for you!</span>
        </div>
        <img
          src="event_page.png"
          alt="event page"
          className="gif-image-with-border"
        />
        <div className="get-started-button-container">
          <Link to='/new'><button className="get-started-button-reverse">Get Started for FREE</button></Link>
        </div>
      </div>
    </section>
  );
};


export default MiddleSection;
