import React, { useEffect, useState } from 'react';
import '../App.css';
import './table.css';
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';
import useAPIService from '../APIHandler/APIService';
import HeaderBar from '../NavBars/HeaderBar';
import { Edit, Trash, Users } from 'react-feather';
import './ShowAllPaymentCSS.css';
import CancelArrow from '../Buttons/CancelArrow';
import LoadingAnimation from '../Animations/Loading/LoadingAnimation';
import { PaymentState } from './PaymentCreate';
import Message, { MessageProps } from '../Helper/Messaging/Message';

const ShowAllPayment = () => 
{
    const {eventid}:any = useParams();
    const location = useLocation();
    const [allPayment,setAllPayment] = useState<any[]>([]);
    const navigate = useNavigate();
    const apiService = useAPIService();
    const parentEventPageURL = '/events/id/' + eventid;
    const [loading, setLoading] = useState(true);
    const [showUsersPaymentsOnly, setShowUsersPaymentsOnly] = useState(false);
    const [requestedById,setRequestedById] = useState();
    const [dataFetched, setDataFecthed] = useState(false);
    const [currencyList, setCurrencyList] = useState<any>({});

    const [messaging, setMessaging] = useState<MessageProps>({
        status: 'success',
        message: '',
    });
    const USERS_PAYMENTS_ONLY_KEY = 'showUsersPaymentsOnly';

    const actionButtons = (paidMemberName:string,
                           transactionId:string,
                           subject:string,
                           targetMemberIdsList:string[],
                           amount:number,
                           paidMemberId:string,
                           currencyType:string,
                           isPayback:boolean,
                           paybackId?:string) => 
    {
        let returnedEventId:any;

        const deleteAction = async () => {
            const confirmed = window.confirm('Are you sure you want to delete this?');
            const endpoint = isPayback ? `/api/paymentAdjustment/delete/event/id/${eventid}/${paybackId}` : `/api/payment/delete/event/id/${eventid}/${transactionId}`
            if (!confirmed) {
                return;
            }
            await fetch(`${endpoint}`, {
                method : "DELETE"
            })
            .then(response => response.json())
            .then(data => { returnedEventId = data;
            })

            navigate('/payment/showAll/' + returnedEventId, {
                state : {
                  resultMessage : "Deleted"
                }
            });
        }

        const editAction = () => {
            const paymentState : PaymentState = {
                subject: subject,
                eventId: eventid,
                previousPageURL: location.pathname,
                paidMemberId: paidMemberId,
                amount: amount,
                targetMemberIdsList: targetMemberIdsList,
                id: transactionId,
                paidMemberName: paidMemberName,
                currencyType : currencyType,
                isPayback : isPayback
            }

            navigate(`/payment/event/id/${eventid}/edit`, {
                state : {
                    paymentState
                }
            }
            );
        }
        return  <div>
                    {isPayback ? null : (<div className='payment-actions-container-bottom-right-adjacent'><Edit size={15} onClick={editAction}></Edit></div>)}
                    {/* <div className='payment-actions-container-bottom-right-adjacent'><Edit size={15} onClick={editAction}></Edit></div> */}
                    <div className='payment-actions-container-bottom-right'><Trash size={13} onClick={deleteAction}>Delete</Trash></div>
                </div>

    }

    const paymentTable = () => {
        let builtTable:any = [];
        let subjectColumn;
        let paidMemberColumn;
        let targetMemberColumn:any = [];
        let amount:any = [];
        let keyTransactionId:string;
        let actionButton;

        for(let parentPayment of allPayment) {

            if(parentPayment.isPayback) {
                parentPayment.subject = `[Payback] ${parentPayment.paidMemberName} → ${parentPayment.targetMemberName}`;
                parentPayment.targetMemberIds = [];
                parentPayment.targetMemberNameList = [];
            }

            keyTransactionId = parentPayment.id;
            const targetMemberIds = parentPayment.targetMemberIdsList;
            const targetMemberNameList = parentPayment.targetMemberNameList;
            const currencySign = currencyList[parentPayment.currencyType];
            // If toggled to show only the user's payments, skip other payments
            if(showUsersPaymentsOnly) {
                if(parentPayment.isPayback) {
                    console.log(`subject : ${parentPayment.subject} requestyBy Id is ${requestedById} paidMemberId is ${parentPayment.paidMemberId} targetMemberId is ${parentPayment.targetMemberId}`)
                    if(parentPayment.paidMemberId !== requestedById && parentPayment.targetMemberId !== requestedById) {
                        continue;
                    }
                } else if(parentPayment.paidMemberId !== requestedById && !targetMemberIds.includes(requestedById)) {
                    continue;
                }
            }

            subjectColumn = 
                <div onClick={ (e) => handleClick(e,parentPayment)} id="payment_subject" className='payment-subject'>{parentPayment.subject}</div>
            paidMemberColumn = parentPayment.isPayback ? null : 
                (<div className='payer'>
                    <div>paid by {parentPayment.paidMemberName}</div>
                    <div className='payment-target-members'><Users size={12}/>{targetMemberNameList.length}</div>
                </div>)
            amount = <div className='amount'>{currencySign} {parentPayment.amount}</div>

            actionButton = actionButtons(parentPayment.paidMemberName,
                                         keyTransactionId,
                                         parentPayment.subject,
                                         targetMemberIds,
                                         parentPayment.amount,
                                         parentPayment.paidMemberId,
                                         parentPayment.currencyType,
                                         parentPayment.isPayback,
                                         parentPayment?.id)

            targetMemberColumn.push( 
                <div className='payment-target-members'><Users size={12}/>{targetMemberNameList.length}</div>
            )

            const uniqueKey = keyTransactionId + parentPayment.paidMemberId;
            builtTable.push(
                <div key={uniqueKey} className='payment-thread-container'>
                    <div className='payment-thread-item'>
                        {subjectColumn}
                        {amount}
                        {paidMemberColumn}
                        {actionButton}
                    </div>
                </div>
            )
            keyTransactionId = "";
            subjectColumn = undefined;
            paidMemberColumn = undefined;
            targetMemberColumn = [];
            amount = [];
        }
        return builtTable;
    }

    const sortDTOsByCreatedAt = (result:any) => {
        const sortedArray = [...result].sort((a,b) => {
            const dateA:any = new Date(a.createdAt);
            const dateB:any = new Date(b.createdAt);
            return dateA - dateB;
        })
        return sortedArray;
    }

    useEffect( () => {
        const storedShowUsersPaymentsOnlyValue = localStorage.getItem(USERS_PAYMENTS_ONLY_KEY);
        if(storedShowUsersPaymentsOnlyValue) {
            const showUsersPaymentsOnlyBoolean = storedShowUsersPaymentsOnlyValue === 'true' ? true : false;
            setShowUsersPaymentsOnly(showUsersPaymentsOnlyBoolean);
        }

        if(!dataFetched) {
            const response = apiService.getByURL(`/api/payment/allpayment/${eventid}`);
            response.then(data => {
                const paymentDTOs = data.paymentDTOs;
                const paymentAdjustmentDTOs = data.adjustmentDTOs;
                const result = [...paymentDTOs,...paymentAdjustmentDTOs];
                const sortedDTOs = sortDTOsByCreatedAt(result);

                setAllPayment(sortedDTOs);
                setRequestedById(data.requestedBy);
                setLoading(false);
                setDataFecthed(true);
            })
            const currencyListResponse = apiService.getByURL('/api/currencies/getAllCurrencies')
            currencyListResponse.then(data => {
                const currencies = data.currencies;
                for(const currency of currencies) {
                    setCurrencyList((prevState:any) => ({...prevState,[currency.currencyTypeCode] : currency.currencyTypeSymbol}))
                }
            })
            return;
        }
        setLoading((prevState) => !prevState);
        paymentTable();
    },[showUsersPaymentsOnly]);

    if (loading) {
        return <div>
                    <HeaderBar/>
                    <LoadingAnimation/>
             </div>
    }


    const addAction = () => 
    {
        navigate(`/events/id/${eventid}/payment/new`, {
            state : {
                previousPageURL : location.pathname
            }
        });
    }

    const handleClick = (e:any,paymentState:any) => {
        if(paymentState.isPayback) {
            // For now, no read page for payback details
            return;
        }
        paymentState.previousPageURL = location.pathname;
        if(e.target.id === 'payment_subject') {
            navigate(`/payment/read/id/${paymentState.id}`, {
                state : paymentState
            });
        }

    }

    const toggleSwitch = () => {
      setShowUsersPaymentsOnly((prevState) => {
        setLoading(true);
        localStorage.setItem(USERS_PAYMENTS_ONLY_KEY,(!prevState).toString());
        const messageString = !prevState ? 'Showing only your payments' : 'Showing all payments';
        setMessaging((messaging) => ({...messaging, message :messageString}))
        return !prevState
      });
    };

    return (
        <div>
          <HeaderBar />
          <div className='show-all-payment-header'>
            <CancelArrow URL={parentEventPageURL} />
            <div className='show-all-payment-title'>
            <div className='payment-list-title'>Payment list</div>
              <button className="add-payment-button" onClick={addAction}>
                Add Payment
              </button>
            </div>
          </div>
          <div className="toggle-section">
            <div className="toggle-label">Show your payments only</div>
            <label className="toggle-switch">
              <input
                type="checkbox"
                checked={showUsersPaymentsOnly}
                onChange={toggleSwitch}
              />
              <span className="slider round"></span>
            </label>
          </div>
          <div>{paymentTable()}</div>
          {!loading && messaging.message !== '' && <Message {...messaging}/>}
        </div>
      );
      
    };
  
export default ShowAllPayment;