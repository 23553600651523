import HeaderBar from '../NavBars/HeaderBar';
import TopSection from './TopSection';
import MiddleSection from './MiddleSection';
import BottomSection from './BottomSection';
import AuthenticationChecker from '../Authentication/AuthenticationChecker';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

const Welcome = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const result = fetch('/doAuth');
        result.then(response => {
            if(response.status === 200) {
                navigate('/home');
            }
            setLoading(false);
        });

    },[]);

    if(loading) {
        return <div><HeaderBar disableNav={true}/></div>
    }

    return (
        <div>
            <HeaderBar isWelcome={true}/>
            <TopSection/>
            <MiddleSection/>
            <BottomSection/>
        </div>
    );
}

export default Welcome;