import loadingGif from "./Loading.gif";
import "./loading.css";


const LoadingAnimation = () => {
    return  <div className="loading-icon-container">
                <img className="loading-icon-size" src={loadingGif} alt="Loading"></img>
            </div>
}

export default LoadingAnimation;