import React, { useEffect, useState } from 'react';
import logo from '../logo.svg';
import '../App.css';
import AppNavbar from '../NavBars/AppNavbar';
import { Table } from 'reactstrap';
import TransactionList from './TransactionList';

const PaymentForm = () => 
{
  
    return (
      <div>
            <h2>Payments</h2>
            TBD
      </div>
    );
}
  
  export default PaymentForm;