import { Form, FormGroup, Input, Label } from "reactstrap";
import CancelArrow from "../Buttons/CancelArrow";
import GeneralButton from "../Buttons/GeneralButton";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { transactionState } from './TransactionMap';
import LoadingAnimation from "../Animations/Loading/LoadingAnimation";
import MainContentContainer from "../Containers/MainContentContainer";
import './PaymentAdjustmentCSS.css';

const PaymentAdjustmentCreate = () =>
{
    const navigate = useNavigate();
    const { state }: any = useLocation();
    const sourceLocation = state?.location;
    const transactionStates = state?.transactionStates;

    const [loading, setLoading] = useState(true);
    const [remainingAmountObj, setRemainingAmountObj] = useState<any>({});
    const [paybackStates, setPaybackState] = useState<any>({});

    const [errors, setErrors] = useState<any>({});
    const [eventId, setEventId] = useState<string>("");

    useEffect(() => {
        if(sourceLocation === null || !doesStateMeetRequiredFields(transactionStates)) {
            navigate('/home');
        }

        for(const transactionState of transactionStates) {
            setPaybackState((prevState:any) => ({...prevState, 
                [transactionState.targetMemberId] : {
                    amount : 0,
                    paidMemberId : transactionState.subjectMemberId,
                    eventId : transactionState.eventId,
                    targetMemberId : transactionState.targetMemberId,
                    currencyType : transactionState.currencyType
                }}));
            setRemainingAmountObj((prevState:any) => ({...prevState, [transactionState.targetMemberId] : transactionState.amount}))
            setEventId(transactionState.eventId);
        }

        setLoading(false);
      }, [transactionStates]);

    const handlePaybackChange = (e:any, state:any) => {
        const { name, value } = e.target;

        if(value === '') {
            setRemainingAmountObj((prevState:any) => ({...prevState, [state.targetMemberId] : state.amount}))
        } else {
            const paybackNumeric = parseFloat(value);
            const remainingValue:number = calculateRemaining(paybackNumeric, state.amount);

            setRemainingAmountObj((prevState:any) => ({...prevState, [state.targetMemberId] : remainingValue}));
            setErrors((prevState:any) => ({...prevState, [state.targetMemberId] : ''}));
        }

        setPaybackState((prevState:any) => ({...prevState, 
            [state.targetMemberId] : {
                ...prevState[state.targetMemberId],
                amount : value
            }}));
    }

    const calculateRemaining = (paybackAmount:number, remainingAmount:number) => {
        return remainingAmount + paybackAmount;
    }

    if(loading) {
        return <LoadingAnimation/>
    }

    // this handles creation and edit depending on whether there is already event id or not
    const handleSubmit = async (e:any) => {
        e.preventDefault();
        let hasError = false;

        const keys = Object.keys(paybackStates);
        for(const key of keys) {
            if(paybackStates[key].paybackAmount === 0 || paybackStates[key].paybackAmount === '') {
                setErrors((prevState:any) => ({...prevState, [paybackStates[key].targetMemberId] : 'Enter a value greater than 0'}));
                hasError = true;
            }
        }

        if(hasError) {
          return;
        }

        await fetch(`/api/payment/payback/`,
        {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(paybackStates)
        })
        .then(response => {
            if(response.ok) {
                navigate(`/events/id/${eventId}`, {
                    state : {
                        messaging : 'payback added!'
                    }
                  })
            }
        })


    }

    const title = <h2>Adjust Payment</h2>

    return (
          <div>
              <MainContentContainer>
                <CancelArrow URL={sourceLocation.pathname}/> 
                {title}
                <Form onSubmit={handleSubmit}>
                    {transactionStates && transactionStates.map((transactionState: any, index: any) => {
                        const elementId = transactionState.targetMemberId;
                        return (
                            <FormGroup>
                            <Label for={elementId}>{transactionState.targetMemberName} 
                                <div><span className="payback-label-texts">remaining : </span><span className={remainingAmountObj[elementId] > 0 ? 'overpaying-value' : remainingAmountObj[elementId] < 0 ? 'remaining-debt-value' : ''}>
                                    {remainingAmountObj[elementId]}
                                    </span>
                                </div>
                            </Label>
                            <Input type="number" name={elementId} id={elementId} value={paybackStates[elementId]?.amount || ''}
                                    onChange={(e) => handlePaybackChange(e,transactionState)} autoComplete="name" placeholder="0"/>
                            {errors[elementId] && <p className="submit-error-message-text">{errors[elementId]}</p>}
                            </FormGroup>
                        )
                    })}
                    <div>Currency : {transactionStates[0].currencyType}</div>
                  <FormGroup>
                    <GeneralButton>Save</GeneralButton>
                  </FormGroup>
                </Form>
              </MainContentContainer>
          </div>
    )
}

function doesStateMeetRequiredFields(transactionStates:any): transactionStates is transactionState {

    if(transactionStates === null || transactionStates === undefined) {
        return false;
    }

    if(transactionStates.length === 0) {
        return false;
    }

    return(
        typeof transactionStates[0].id === 'string' &&
        typeof transactionStates[0].subjectMemberId === 'string' &&
        typeof transactionStates[0].targetMemberId === 'string' &&
        typeof transactionStates[0].amount === 'number'
    );
}

export default PaymentAdjustmentCreate;