import {useEffect, useState} from 'react';
import '../App.css';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';
import useAPIService from "../APIHandler/APIService";
import MainContentContainer from '../Containers/MainContentContainer';
import GeneralButton from '../Buttons/GeneralButton';
import CancelArrow from '../Buttons/CancelArrow';
import CurrencyFormDropDown from '../Currency/CurrencyFormDropDown';

export interface PaymentState {
  subject : string,
  eventId : string,
  previousPageURL : string,
  paidMemberId : string,
  amount : number,
  targetMemberIdsList : string[],
  id : string,
  paidMemberName : string,
  currencyType? : string,
  isPayback : boolean
}

const PaymentCreate = () =>
{
    const location:any = useLocation();
    const {id,action} = useParams();
    const isEdit = action === "edit";
    const apiService = useAPIService();
    const SUBJECT_MAX_LENGTH = 110;
    const receivedState = location.state.paymentState;
    const [errors, setErrors] = useState({subject : '', amount: ''});
    const navigate = useNavigate();

    let backArrowURL = location.state?.previousPageURL || receivedState.previousPageURL || '';
    let preservedTargetMemberList = receivedState ? receivedState.targetMemberIdsList : [];
    let selectedPaidMemberName:string = receivedState ? receivedState.paidMemberName : '';
    let selectedPaidMemberId:string = receivedState ? receivedState.paidMemberId : '';
    let subject = receivedState ? receivedState.subject : '';
    let amount = receivedState ? receivedState.amount : 0;
    const isPayback = receivedState ? receivedState.isPayback : false;
    let transactionId = receivedState ? receivedState.id : '';
    let currencyType = receivedState ? receivedState.currencyType : 'JPY';
    let eventId = `${id}` ? `${id}` : "";

    const initialFormState = {
        subject:subject || "",
        eventId:eventId,
        amount:amount || 0,
        targetMemberIdsList: preservedTargetMemberList || [],
        paidMemberId: selectedPaidMemberId || "",
        id:transactionId,
        currencyType: currencyType,
        isPayback : isPayback
    };

    const [payment,setPayment] = useState(initialFormState);
    const [eventMembers,setEventMembers] = useState([]);
    const [paidEventMemberName, setPaidEventMemberName] = useState('');
    const [previousPageURL,setPreviousPageURL] = useState('');
    const [targetEventMemberListStore,setTargetEventMemberListStore] = useState<string[]>([]);

    useEffect( () => {
      if(backArrowURL !== undefined) {
          setPreviousPageURL(backArrowURL);
      }

      const response = apiService.getEventMembers(`${id}`);
      response.then(data => {
          if(data.length === 0){
              return;
          }
          setEventMembers(data.eventMembers);
          if(!receivedState) {
              let tempIdList:string[] = [];
              let paidUserId = '';
              data.eventMembers.forEach((eventMember: any) => {
                      tempIdList.push(eventMember.userId);
                  if (eventMember.userId === data.requestedById) {
                      setPaidEventMemberName(eventMember.name);
                      paidUserId = eventMember.userId;
                  }
              });
              // Set initial paid member Id and target members
              setPayment({...payment,
                paidMemberId:paidUserId,
                targetMemberIdsList : tempIdList});
                setTargetEventMemberListStore(tempIdList);
          } else {
              setPaidEventMemberName(selectedPaidMemberName);
              setTargetEventMemberListStore(preservedTargetMemberList);
          }
      });
    },[]);

    const handleChange = (e:any) => {
        const { name, value } = e.target
        if(name === "targetMembers") {
            const isChecked = e.target.checked ? true : false;
            const updatedMemberId:string =  value;
            if(isChecked && !targetEventMemberListStore.includes(updatedMemberId)) {
                let newList:string[] = targetEventMemberListStore;
                newList.push(updatedMemberId);
                setTargetEventMemberListStore(prevState => [...targetEventMemberListStore,value]);
                setPayment({...payment,targetMemberIdsList:newList});
            } else if (!isChecked) {
                const newList:string[] = targetEventMemberListStore.filter(targetMember => targetMember !== updatedMemberId);
                setTargetEventMemberListStore(newList);
                setPayment({...payment,targetMemberIdsList:newList});
            }
        } else {
            setPayment({...payment,[name]:value});
        }
    }

    let returnedEventId:any;
    const handleSubmit = async (e:any) => {
        e.preventDefault();
        setErrors({subject : '', amount: ''});
        if(payment.subject === undefined || payment.subject === '') {
            setErrors((prevErrors) => ({...prevErrors,subject:'Please enter subject'}));
            return;
        }
        if(payment.amount === undefined || payment.amount === 0) {
            setErrors((prevErrors) => ({...prevErrors,amount:'Please enter a number greater than 0'}));
            return;
        }

        await fetch(`/api/payment/update/`,
        {
          method: (isEdit) ? 'PUT' : 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payment)
        })
        .then(response => {
          if (response.status > 400) {
            navigate('/login');
          } 
          return response.json()})
        .then(data => returnedEventId = data);

        // setPayment(initialFormState);
        if(returnedEventId == null) {
          returnedEventId = payment.eventId;
        }
        navigate('/events/id/' + returnedEventId, {
          state : {
            messaging : "Success!"
          }
        });
      }

    const title = <h2>{isEdit ? 'Edit Payment' : 'Create Payment'}</h2>;

    return (
          <div>
              <MainContentContainer>
                <CancelArrow URL={backArrowURL}/>
                {title}
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Label for="subject">Payment subject</Label>
                    <Input type="text" 
                           name="subject" 
                           maxLength={SUBJECT_MAX_LENGTH} 
                           id="subject" 
                           value={payment.subject || ''}
                           onChange={handleChange}
                           readOnly={payment.isPayback ? true : false}
                    />
                    {errors.subject && <p className='submit-error-message-text'>{errors.subject}</p>}
                  </FormGroup>
                  <CurrencyFormDropDown
                    currencyType={payment.currencyType}
                    handleChange={handleChange}
                  />
                  <FormGroup>
                    <Label for="amount">Paid Amount</Label>
                    <Input type="number" name="amount" id="amount" value={payment.amount || ''}
                          onChange={handleChange} readOnly={payment.isPayback ? true : false}/>
                    {errors.amount && <p className='submit-error-message-text'>{errors.amount}</p>}
                  </FormGroup>
                  <FormGroup>
                        <Label for={"paidMember"}>Who Paid?&nbsp;</Label>
                        <Button size="sm" outline color="primary" tag={Link} to={`/payment/paidEventMembersSelect`}
                                state={{
                                    from : location.pathname,
                                    eventMembers : eventMembers,
                                    previousPageURL : previousPageURL,
                                    eventId : payment.eventId,
                                    selectedPaidMemberId: payment.paidMemberId,
                                    selectedTargetMembers:payment.targetMemberIdsList,
                                    subject:payment.subject,
                                    amount:payment.amount,
                                    id : payment.id,
                                    currencyType : payment.currencyType
                                }}
                                disabled={payment.isPayback ? true : false}>{paidEventMemberName}</Button>
                    </FormGroup>
                    {eventMembers.map((eventMember:any) => {
                        let isSelected = true;
                        if(receivedState) {
                          isSelected = (payment.targetMemberIdsList.includes(eventMember.userId));
                        } 
                        return  <Label for="targetMembers" key={eventMember.id}>
                                    <Input id={eventMember.id} 
                                           name="targetMembers" 
                                           onChange={handleChange} 
                                           type="checkbox" 
                                           value={eventMember.userId} 
                                           defaultChecked={isSelected}
                                    />
                                    {eventMember.name}
                                </Label>
                    })}
                  <FormGroup>
                    <GeneralButton>Save</GeneralButton>&nbsp;
                  </FormGroup>
                </Form>
              </MainContentContainer>
          </div>
    )
}
export default PaymentCreate;