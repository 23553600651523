import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";

const AuthenticationChecker = ({children}:any) => {
    const navigate = useNavigate();
    const [isAuthed,setIsAuthed] = useState(false);

    useEffect(() => {
        const result = fetch('/doAuth');
        result.then(response => {
            if(response.status === 200) {
                setIsAuthed(true);
                return;
            }
            response.json().then( data => {
                navigate(data.redirectURL)
            })
        });
    }, []);

    return isAuthed ? children : null;
}

export default AuthenticationChecker;
