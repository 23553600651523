import HeaderBar from "../NavBars/HeaderBar";
import BottomSection from "./BottomSection";
import FaqMiddleSection from "./FaqMiddleSection";

const Faq = () => {
    return (
        <div>
            <HeaderBar isWelcome={true}/>
            <FaqMiddleSection/>
            <BottomSection/>
        </div>
    )
}

export default Faq;