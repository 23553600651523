import React, { useEffect, useState } from 'react';
import logo from '../logo.svg';
import '../App.css';
import AppNavbar from '../NavBars/AppNavbar';


const Settings = () => {


    return (
        <h3>Settings Pages. TBD</h3>

    );
}

export default Settings;