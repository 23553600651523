import React, { useState } from 'react';
import './MobileNavigationMenuCSS.css';
import { AlertCircle, Feather, HelpCircle, Home, Mail, User } from 'react-feather';

const WelcomeNavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="menu-container">
      <input
        type="checkbox"
        id="menu-toggle"
        className="menu-toggle"
        checked={isOpen}
        onChange={toggleMenu}
      />
      <label htmlFor="menu-toggle" className={`menu-icon ${isOpen ? 'open' : ''}`}>
        <span className="menu-icon-line"></span>
        <span className="menu-icon-line"></span>
        <span className="menu-icon-line"></span>
      </label>
      <div className={`menu${isOpen ? ' open' : ''}`}>
        <ul className="menu-items">
          <li><a href="/login"><User  size={16}/><span className='menu-link-text'>Login</span></a></li>
          <li><a href="/"><Home  size={16}/><span className='menu-link-text'>Home</span></a></li>
          <li><a href="/blogs"><Feather size={16}/><span className='menu-link-text'>Developer Blog</span></a></li>          
          <li><a href="/faq"><HelpCircle size={16}/><span className='menu-link-text'>FAQ</span></a></li>
          <li><a href="/privacy"><AlertCircle size={16}/><span className='menu-link-text'>Privacy Policy</span></a></li>
          <li><a href="mailto:thenecessaryapp@gmail.com"><Mail size={16}/><span className='menu-link-text'>Contact Us</span></a></li>
          {/* Add more list items as needed */}
        </ul>
      </div>
    </div>
  );
};

export default WelcomeNavBar;
