import React, {useEffect, useRef, useState} from 'react';
import {Button} from "reactstrap";
import {useNavigate, useParams} from "react-router-dom";
import GeneralButton from '../Buttons/GeneralButton';
import { Copy, Link } from 'react-feather';
import './InvitationURLCSS.css';

const InvitationURLGenerateButton = (state:any) =>
{
    const eventId = state.eventId;
    const linkRef = useRef(null);
    const defaultMessage:string = "Invite by URL";
    const [invitationUrl,setInvitationUrl] = useState("");
    const navigate = useNavigate();
    const {id} = useParams();
    const [copied, setCopied] = useState(false);

    const handleClick = async () => {
       await fetch(`/api/events/invitation/${eventId}`)
            .then(response => {
                if(response.status !== 200) {
                    navigate('/login');
                }
                return response.text();
            })
           .then(data => {
               setInvitationUrl(data);
           })
    }

    useEffect( () => {
         fetch(`/api/events/invitation/${eventId}`)
        .then(response => {
            if(response.status !== 200) {
                navigate('/login');
            }
            return response.text();
        })
       .then(data => {
           setInvitationUrl(data);
       })
    },[]);

    const handleLinkIconClick = () => {
        // @ts-ignore
        navigator.clipboard.writeText(linkRef.current.textContent);
        setCopied(true);

        setTimeout( () => {
            setCopied(false);
        },3000)
    }

    return (
        <div>
            <p ref={linkRef} className='inv-link-style'>{invitationUrl}</p>
            <div className='bordered-container bordered-container-top'>
                <div className='link-icon-wrapper' onClick={handleLinkIconClick}>
                    <Copy size={16} />
                    <span className='link-icon'>Copy Link</span>
                </div>
            </div>
            {/* <div className='bordered-container'>
                <div>change link</div>
            </div> */}
            {copied && <span className='link-copied-tooltip'>Copied!</span>}
        </div>
    );
}

export default InvitationURLGenerateButton;