import { useLocation, useParams } from "react-router-dom";
import MainContentContainer from "../Containers/MainContentContainer";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { EventMemberState } from "./EventMemberCreate";
import { useState } from "react";

const EventMemberEdit = () => {

    const location:any = useLocation();
    const eventMember:EventMemberState = location.state;
    const [eventMemberState, setEventMemberState] = useState<EventMemberState>(eventMember);

    const handleSubmit = (e:any) => {
        const {name,value} = e;

    }

    const handleChange = (e:any) => {
        const {name,value} = e;
        if(name === 'name') {
            setEventMemberState({...eventMemberState,[name]:value});
        }
    }

    return (
        <MainContentContainer>
            <Form onSubmit={handleSubmit}>
                <FormGroup>
                    <Label for="name"></Label>
                    <Input type="text" name="name" id="name" value={eventMember.name} onChange={handleChange}></Input>
                </FormGroup>
            </Form>
        </MainContentContainer>
    );
}

export default EventMemberEdit;