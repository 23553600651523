import { APP_NAME } from "../../Constants";
import HeaderBar from "../../NavBars/HeaderBar";
import BottomSection from "../BottomSection";
import './BlogCSS.css';

const SecondBlog = () => {
    return (
        <div>
            <HeaderBar isWelcome={true}/>
            <div className="blog-body-container">
                <section className="blog-middle-section">
                    <h1 className="blog-title">Basic concept of how Split App simplifies payments</h1>
                    <div className="feature">
                        <p className="with-border">
                            December 28th, 2023
                        </p>
                    </div>
                    <div className="feature">
                        <p className="feature-description">
                            Splitting payments among friends can quickly become a logistical puzzle. 
                            Should you pay back each person individually, or is there a simpler way to settle the debts? 
                            Split App, with its core feature of optimizing payments, aims to provide the shortest path to settling expenses within a group while maintaining a straightforward approach.
                        </p>
                        <p className="feature-description">
                            One of the guiding principles of Split App is simplicity. 
                            Unlike some applications that focus on summarizing transactions without clear logic, Split App is designed to streamline the settlement process without suggesting payments to individuals you don't directly owe. 
                            Let's delve into how Split App works with a straightforward example:
                        </p>
                        <p>
                            Imagine a group of three friends: Friend A, Friend B, and Friend C.
                        </p>
                        <p>
                            Friend A covers the taxi fee for all three, totaling $60.
                            Friend B pays for lunch for themselves and Friend C, totaling $40.
                        </p>
                        <p>
                            Following this, the individual balances are as follows:
                        </p>
                        <p>
                            - Friend B owes Friend A $20
                            - Friend C owes Friend A $20
                            - Friend C owes Friend B $20
                        </p>
                        <p>
                            In a typical scenario, each friend might repay what they owe individually. 
                            However, Split App simplifies the process by summarizing the transactions:
                        </p>
                        <p>
                            - Friend C owes Friend A $40
                        </p>
                        <p>
                            This consolidation cancels out the "Friend C owes Friend B $20" by combining it with the debt that Friend C has to Friend A. 
                            Friend C pays the $20 owed by Friend B to Friend A. This straightforward example illustrates the concept of optimizing payments.
                        </p>
                        <p>
                            Now, consider expanding this scenario to a larger group of, say, ten people, each covering expenses for different subsets of the group.
                             The calculations become significantly more complex. Split App steps in to untangle the intricacies, providing a user-friendly solution for settling debts within a group.
                        </p>
                        <p>
                            In essence, Split App simplifies group expenses, ensuring that you settle your debts with the shortest and most straightforward path. 
                            By focusing on direct obligations and avoiding unnecessary complexity, Split App stands as a practical tool for anyone navigating shared expenses within a social circle.
                        </p>
                    </div>
                </section>
        </div>
            <BottomSection/>
        </div>
    )
}

export default SecondBlog;