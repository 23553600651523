import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "../Animations/Loading/loading.css";
import HeaderBar from "../NavBars/HeaderBar";
import MainContentContainer from "../Containers/MainContentContainer";
import "./PaymentSinglePageCSS.css";
import { Edit, Users } from "react-feather";

const PaymentSinglePage = () => {

    const {state : paymentState}:any = useLocation();

    const {action} = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const invalidRequest = paymentState === null || action !== 'read';
    const [payment,setPayment] = useState<any>({});
    
    let targetMemberNameList:string[] = [];
    let backArrowURL;

    useEffect( () => {
        if(invalidRequest) {
            navigate('/home');
        }
    },[]);

    if(invalidRequest) {
        // short circuit, if its invalid
        return null;
    }
    const currencySign = paymentState.currencyType === 'JPY' ? '¥' : 'NT$';
    backArrowURL = `/payment/showAll/${paymentState.eventId}`;
    targetMemberNameList = paymentState.targetMemberNameList;
  
    const editAction = () => {
        paymentState.previousPageURL = location.pathname;
        navigate(`/payment/event/id/${paymentState.eventId}/edit`,{state : {paymentState}});
    }

    return <div>
                <HeaderBar/>
                <MainContentContainer>
                    <div className="payment-single-container">
                        <div className="payment-single-subject">{paymentState.subject}</div>
                        <div className="payment-single-edit-icon "><Edit size={17} onClick={editAction}/></div>
                        <div className="payment-single-row">    
                            <div className="payment-single-payer">Paid by : {paymentState.paidMemberName}</div>
                            <div className="payment-single-amount">{currencySign} {paymentState.amount}</div>
                        </div>
                        <div className="payment-single-members-container">
                            <div className="payment-sing-members-title"><Users size={15}/> members </div>
                            <ul>
                                {targetMemberNameList.map( (name,index) => (
                                <li>{name}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </MainContentContainer>
           </div>;
}

export default PaymentSinglePage;