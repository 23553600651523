import React, { ReactNode } from 'react';
import './GeneralButton.css';
import { Link } from 'react-router-dom';

interface ButtonProps {
    children: ReactNode;
    to?: string;
    onClick?: () => void;
    state?:any;
    size?:'small';
  }

  
const GeneralButton:React.FC<ButtonProps> = ({ children,to,onClick,state,size}) => 
{
    const styleClass = size ? "generalbutton-" + size : "generalbutton";
    if(to) {
        if(state) {
            return (
                <Link to={to} className={styleClass} state={state}>
                    {children}
                </Link>
            );
        }
        return (
            <Link to={to} className={styleClass}>
                {children}
            </Link>
        );
    }

    return (
        <button className={styleClass} onClick={onClick}>
            {children}
        </button>
    );
};

export default GeneralButton;