import { APP_NAME } from "../../Constants";
import HeaderBar from "../../NavBars/HeaderBar";
import BottomSection from "../BottomSection";
import './BlogCSS.css';

const ThirdBlog = () => {
    return (
        <div>
            <HeaderBar isWelcome={true}/>
            <div className="blog-body-container">
                <section className="blog-middle-section">
                    <h1 className="blog-title">Unlocking the Magic of Split App: Embracing the Power of Events</h1>
                    <div className="feature">
                        <p className="with-border">
                            December 29th, 2023
                        </p>
                    </div>
                    <div className="feature">
                        <p>
                            At first glance, the Split App might seem like it revolves around a sophisticated concept called "events." 
                            However, in simple terms, an event here is just a digital record that captures the participants and transactions occurring within a specific gathering. 
                            Imagine it as a detailed diary of who chipped in and what expenses were covered during that particular event.
                        </p>
                        <p>
                            The simplicity is the key to why this app is great. Each payment is neatly separated, making it a breeze to manage and understand. 
                            For users, this means crystal-clear insights into their spending habits during events. Who would have thought that looking back at payment records could be such a sentimental journey?
                        </p>
                        <p>
                            Now, why does the app insist on users creating an account? Well, that's where the magic happens. 
                            Having an account transforms the user experience. Suddenly, all your past events are accessible from a single, user-friendly page. 
                            No more hunting for receipts, scrolling through credit card histories, or digging through endless group chat messages to decipher who paid for what.
                        </p>
                        <p>
                            Sure, if you're a one-time user, creating an account might seem like a bit of an extra step. 
                            But here's the kicker: for those who find themselves using the app repeatedly, having an account becomes a game-changer.
                        </p>
                        <p>
                            Imagine this app gaining more popularity and attracting a growing user base.
                            The good news is that the developers are committed to enhancing user experience further.
                            As the user community expands, they plan to roll out additional features seamlessly integrated with the event concept. 
                            This means users with accounts will effortlessly tap into these upgrades, making their Split App experience even more delightful.
                        </p>
                        <p>
                            In essence, what makes Split App great lies not only in its event-centric structure but also in the simplicity an account brings. 
                            So, next time you split expenses at an event, let Split App be your companion, making every payment a memorable part of your digital journey.
                        </p>
                    </div>
                </section>
        </div>
            <BottomSection/>
        </div>
    )
}

export default ThirdBlog;