import React from 'react';
import './LandingPageCSS.css';
import { Link } from 'react-router-dom';

const TopSection = () => {
    return (
        <section className="top-section">
          <h1 className="top-section-description">Simplify Group Payments with Split App</h1>
          {/* <p className="top-section-faetures">
            - Effortlessly manage shared expenses with your group 💰<br />
            - Perfect for friends gatherings, holidays, and more...<br />
            - Stay tuned for exciting new event organization features!<br/>
            - And to provide you a better experience, creating account is required for this app
          </p> */}
          <div>
            <Link to='/new'><button className="get-started-button">Get Started for Free</button></Link>
          </div>
        </section>
      );
};

export default TopSection;
