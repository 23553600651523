import { useEffect, useState } from "react";
import { CheckCircle } from "react-feather";
import './MessagingCSS.css';

export interface MessageProps {
    status: 'success' | 'failure';
    message: string;
  }

const Message: React.FC<MessageProps> = ({status, message }) => {
    return (
      <div className="message-animation">
        <CheckCircle color="green"/>{message}
      </div>
    );
}

export default Message;