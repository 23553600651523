import './EventListByUserId';
import EventListByUserId from './EventListByUserId';
import './eventHome.css';
import HeaderBar from '../NavBars/HeaderBar';
import GeneralButton from '../Buttons/GeneralButton';
import MainContentContainer from '../Containers/MainContentContainer';
import { useLocation } from 'react-router-dom';

const EventHome = () => {
    const location = useLocation();
    const ownURI = location.pathname;
    return (
        <div>
            <HeaderBar/>
                <MainContentContainer>
                <GeneralButton to="/events/new" state={
                    {previousURL : ownURI}
                }>Create Event</GeneralButton>
                <EventListByUserId/>
                </MainContentContainer>
        </div>
    );
}

export default EventHome;