import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import AppNavbar from '../NavBars/AppNavbar';
import HeaderBar from '../NavBars/HeaderBar';
import MainContentContainer from '../Containers/MainContentContainer';
import TransactionMap from '../Payment/TransactionMap';
import { transactionState } from '../Payment/TransactionMap';
import useAPIService from '../APIHandler/APIService';
import EventCount from '../Events/EventCount';

const Home = () => {

    const [nickName, setNickName] = useState('');
    const apiService = useAPIService();
    useEffect(() => {
        const response = apiService.getByURL('api/users/retrieveName');
        response.then( data => setNickName(data.name));
    }, []);
    
    return (
        <div>
            <HeaderBar/>
            <MainContentContainer>
            <div>Signed in as {nickName}</div>
            <EventCount/>
            </MainContentContainer>
        </div>
    );
}

export default Home;