import { useEffect, useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Label } from "reactstrap";
import useAPIService from "../APIHandler/APIService";

interface CurrencyFormDropDownProps {
  errorMessage?: string,
  dropdownItemName? : string,
  currencyType : string,
  handleChange: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const CurrencyFormDropDown: React.FC<CurrencyFormDropDownProps> = ({currencyType,handleChange,dropdownItemName = 'currencyType', errorMessage = null}) => {
  const apiService = useAPIService();
  const [currencyDropdownOpen, setCurrencyDropdownOpen] = useState(false);
  const [currencyTypeList, setCurrencyList] = useState<string[]>([]);

  const toggleCurrencyDropdown = () => {
      setCurrencyDropdownOpen(!currencyDropdownOpen);
    };

  useEffect(() => {
    const response = apiService.getByURL('/api/currencies/getAllCurrencies');
    response.then(data => {
      const allCurrencies = data.currencies;
      let currencyTypeCodeList = [];
      for(const currency of allCurrencies) {
        currencyTypeCodeList.push(currency.currencyTypeCode);
      }
      setCurrencyList(currencyTypeCodeList);
    })
  },[]);

  return(
      <FormGroup>
        <Label for="currencyType">Currency Type</Label>
        <Dropdown isOpen={currencyDropdownOpen} toggle={toggleCurrencyDropdown}>
          <DropdownToggle caret>
            {currencyType}
          </DropdownToggle>
          <DropdownMenu>
            {currencyTypeList.map(currencyType => (
              <DropdownItem name={dropdownItemName} value={currencyType} onClick={handleChange}>{currencyType}</DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
        {errorMessage && <p className='submit-error-message-text'>{errorMessage}</p>}
      </FormGroup>
  )
}

export default CurrencyFormDropDown;