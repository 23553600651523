import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import HeaderBar from "../NavBars/HeaderBar";
import CancelArrow from "../Buttons/CancelArrow";
import MainContentContainer from "../Containers/MainContentContainer";
import InvitationURLGenerateButton from "../Events/InvitationURLGenerateButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import GeneralButton from "../Buttons/GeneralButton";
import './EventMemberCreate.css';
import { MessageProps } from "../Helper/Messaging/Message";

export interface EventMemberState {
  id? : string,
  name : string,
  email : string
}

const EventMemberCreate = (stateValues:any) => 
{
  const location = useLocation();
  const state:any = location.state;
  const eventId = state.eventId;
  const previousURL = state.url;

  let initState = {
    email : "",
    eventId : eventId
  }

  const [newEventMember, setNewEventMember] = useState(initState);
  const navigate = useNavigate();

    const handleChange = (e:any) => {
      const { name , value } = e.target;
      setNewEventMember({...newEventMember, [name] : value});
    }

    const handleSubmit = (e:any) => {
      e.preventDefault();
        fetch('/api/events/add/eventMember',{
          method : 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(newEventMember)
        })
        .then(response => {
          return response.json();
        })
        .then(data => {
            if(data.result) {
              const messaging:MessageProps = {
                  status : 'success',
                  message : 'Member added'
              }
              navigate(`/events/id/${eventId}`,{
                state : {messaging}
              });
            }
        })
      }

    return  (
        <div>
            <div className="member-create-header">
              <CancelArrow URL={previousURL}/>
              <div className="member-create-titie">add member</div>
            </div>
              <MainContentContainer>
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label for="name">Name</Label>
                  <Input type="text" name="name" id="name" 
                        onChange={handleChange}/>
                  <Label for="email">Email address</Label>
                  <Input type="email" name="email" id="email" 
                        onChange={handleChange}/>
                </FormGroup>
                <FormGroup>
                  <GeneralButton>Add</GeneralButton>{' '}
                </FormGroup>
              </Form>
              </MainContentContainer>
              Join from the link
              <InvitationURLGenerateButton eventId={eventId}/>
        </div>
  )
}

export default EventMemberCreate;