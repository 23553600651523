import React from 'react';
import {Link, useNavigate} from "react-router-dom";
import {Button} from "reactstrap";
import GeneralButton from './GeneralButton';
import { ArrowLeft } from 'react-feather';
import './CancelArrow.css';

const CancelArrow = (state:any) =>
{
    const gotoURL = state.URL;
    const navigate = useNavigate();
    const handleClick = () => {
        navigate(gotoURL);
    }

    return(
        <ArrowLeft className='cancel-arrow-styling' onClick={handleClick}>Cancel</ArrowLeft>
    )
}

export default CancelArrow;